<template>
  <div class="form-info">
    <h1>Não precisa se cadastrar sempre!</h1>
    <p>
      Caso já possua uma conta em nossa loja virtual basta informar o <b>CPF</b> e utilizar o botão
      de <b>buscar</b> para que todos os dados sejam preenchidos.
    </p>
    <p>
      Aperte o botão abaixo para continuar o cadastro:
    </p>
    <button @click="continuarFormulario">
      Continuar
    </button>
  </div>
</template>

<script>
export default {
  name: "FormClienteInfo",
  methods: {
    continuarFormulario() {
      this.$emit('continuarFormulario');
    }
  }
}
</script>

<style scoped>

</style>
