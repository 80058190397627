<template>
  <div>
    <form>
      <div class="form-row">
        <div class="col-md-6 form-group">
          <label for="exampleFormControlInput1">CPF</label>
          <div class="input-group">
            <the-mask
              class="form-control"
              placeholder="000.000.000-00"
              :mask="['###.###.###-##']"
              v-model="value.cpf"
              @blur.native="loadCliente"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                @click="loadCliente"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row" v-show="alertaClienteNaoEncontrado">
        <div class="alert alert-warning" role="alert">
          Não encontrado. Preencha o formulário abaixo!
        </div>
      </div>

      <div class="form-group">
        <label for="inputNome">Nome</label>
        <input
          type="text"
          class="form-control"
          id="inputNome"
          placeholder="Nome completo"
          v-model="value.nome"
        />
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputTelefone">Telefone</label>
            <the-mask
              type="tel"
              class="form-control"
              id="inputTelefone"
              v-model="value.telefone"
              placeholder="(00) 0000-0000"
              :mask="['(##) ####-####', '(##) #####-####']"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputNascimento">Data de nascimento</label>
            <input
              type="date"
              class="form-control"
              id="inputNascimento"
              v-model="value.dataNascimento"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="exampleFormControlInput1">E-mail</label>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput1"
          placeholder="name@example.com"
          v-model="value.email"
        />
      </div>

      <div class="form-row">
        <div class="col-md-6 form-group">
          <label for="exampleFormControlInput1">CEP</label>
          <span class="ml-2">
            (
            <a href="https://buscacepinter.correios.com.br/" target="_blank"
              >Não sabe o seu CEP? Clique aqui!</a
            >)
          </span>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="00000-000"
              aria-label="cpf"
              aria-describedby="button-addon2"
              v-model="value.cep"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="inputRua">Rua</label>
            <input
              type="text"
              class="form-control"
              id="inputRua"
              v-model="value.rua"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="inputNumero">Número</label>
            <input
              type="text"
              class="form-control"
              id="inputNumero"
              v-model="value.numero"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-2">
          <div class="form-group">
            <label for="inputUf">UF</label>
            <select
              class="form-control"
              id="inputUf"
              v-model="value.idUf"
              @change="loadCidades"
            >
              <option
                :key="estado.id"
                :value="estado.id"
                v-for="estado in estados"
              >
                {{ estado.uf }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputCidade">Cidade</label>
            <select
              class="form-control"
              id="inputCidade"
              v-model="value.idCidade"
            >
              <option
                :key="cidade.id"
                :value="cidade.id"
                v-for="cidade in cidades"
              >
                {{ cidade.nome }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="inputBairro">Bairro</label>
            <input
              type="text"
              class="form-control"
              id="inputBairro"
              v-model="value.bairro"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="inputComplemento">Complemento</label>
        <input
          type="text"
          class="form-control"
          id="inputComplemento"
          v-model="value.complemento"
        />
      </div>

      <div class="form-group">
        <label for="inputReferência">Referência</label>
        <input
          type="text"
          class="form-control"
          id="inputReferência"
          v-model="value.referencia"
        />
      </div>
    </form>
  </div>
</template>

<script>
import ClienteService from "../service/ClienteService";
import EnderecoService from "../service/EnderecoService";

export default {
  name: "FormCliente",
  props: ["value"],
  watch: {
    value() {
      this.cliente = this.value;
    },
    cliente: {
      handler: function () {
        this.$emit("input", this.cliente);
      },
      deep: true,
    },
    "cliente.idCidade": function () {
      const cidade = this.cidades.find((c) => c.id === this.cliente.idCidade);
      if (cidade) {
        this.cliente.cidade = cidade.nome;
      }
    },
    "cliente.idUf": function () {
      const estado = this.estados.find((e) => e.id === this.cliente.idUf);
      this.cliente.uf = estado.uf;
    },
  },

  data() {
    return {
      clienteService: new ClienteService(),
      enderecoService: new EnderecoService(),
      cliente: {},
      estados: [],
      cidades: [],
      alertaClienteNaoEncontrado: false,
    };
  },

  methods: {
    async loadCliente() {
      const cpf = this.value.cpf;
      if (cpf) {
        const respose = await this.clienteService.getClientePorCpf(cpf);
        if (Object.keys(respose.data).length) {
          this.alertaClienteNaoEncontrado = false;
          this.cliente = respose.data;
          await this.loadCidades();
        } else {
          this.alertaClienteNaoEncontrado = true;
        }
      }
    },
    async loadEstados() {
      let respose = await this.enderecoService.listarEstados();
      this.estados = respose.data;
      await this.loadCidades();
    },
    async loadCidades() {
      let respose = await this.enderecoService.listarCidadesPorEstado(
        this.value.idUf
      );
      this.cidades = respose.data;
    },
  },

  created() {
    this.loadEstados();
  },
};
</script>
