import api from "./api";

export default class EnderecoService {

    constructor() {
        this.engineUrl = "/admin/engine/painel/lista-de-compras.php";
    }

    listarEstados() {
        return api().get(this.engineUrl, {
            params: {
                action: "listar-estados",
            }
        });
    }

    listarCidadesPorEstado(id) {
        return api().get(this.engineUrl, {
            params: {
                action: "listar-cidades",
                estado: id
            }
        });
    }
}
