<template>
  <div class="container-fluid">
    <form-cliente-info v-if="mostrarMensagemInicial" @continuarFormulario="mostrarMensagemInicialToggle" />
    <form-cliente v-else v-model="cliente" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import FormCliente from "@/components/FormCliente.vue";
import FormClienteInfo from "@/components/FormClienteInfo";

export default {
  name: "DadosCliente",
  components: {
    FormCliente,
    FormClienteInfo
  },
  data() {
    return {
      mostrarMensagemInicial: false
    }
  },
  methods: {
    ...mapMutations({ setCliente: "setCliente" }),
    mostrarMensagemInicialToggle() {
      this.mostrarMensagemInicial = !this.mostrarMensagemInicial;
    }
  },
  computed: {
    ...mapGetters({ getCliente: "getCliente" }),
    cliente: {
      get() {
        return this.getCliente;
      },
      set(value) {
        this.setCliente(value);
      },
    },
  },
  mounted() {
    this.mostrarMensagemInicialToggle();
  }
};
</script>

<style>
</style>
