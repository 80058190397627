import api from "./api";

export default class ClienteService {

    constructor() {
        this.engineUrl = "/admin/engine/painel/lista-de-compras.php";
    }

    getClientePorCpf(cpf) {
        return api().get(this.engineUrl, {
            params: {
                action: "buscar-cliente",
                cpf
            }
        });
    }
}
